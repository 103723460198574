a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #614089bc;
}

a.active {
  color: #614089bc;
}
