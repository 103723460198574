html,
body,
#root,
.app,
.main-content {
  height: 100%;
}

.content-container {
  padding-bottom: 60px;
}
